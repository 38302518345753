import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import InnerSection from "./innerSection"
import ContactForm from "./contactForm"

import "./contactSection.css"

const ContactSection = ( { data, location } ) => {

  return (
    <div className={`${location}-contact contact-section ${data.image ? "" : "no-image"} ${data.type}`}>
      <Container>
        <InnerSection className="contact-data">

          {data.image &&
            <AnimationWrapper className="image-wrapper">
              <GatsbyImage
                className="image-container"
                objectFit="cover"
                image={data.image.childImageSharp.gatsbyImageData}
                alt={data.title}
              />
            </AnimationWrapper>
          }

          <div className="contact-form-container">
            <AnimationWrapper>
              {data.title &&
                <h2 className="section-heading">{data.title}</h2>
              }
              {data.description &&
                <p className="section-description">{data.description}</p>
              }
              <ContactForm title="Topfryz Group" fields={data.fields} btnStyle={data.submit_button_style} />
            </AnimationWrapper>
          </div>

        </InnerSection>
      </Container>
    </div>
  )
}

export default ContactSection