import * as React from "react"

import DownloadTile from "./downloadTile"
import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import Title from "./title"

const DownloadSection = ( {data, className, location } ) => {

    return (
      <Container>
        <AnimationWrapper className={`download-section ${className}`} animationType="fadeInUp">

          <div className="section-title-wrapper">
            <Title className="section-title" title={location} />
          </div>

          <div className={`${location}-download-tiles download-tiles-container`}>
            {data.nodes.map((node) => (
              <DownloadTile file={node}/>
            ))}
          </div>

        </AnimationWrapper>
      </Container>
    )
  }

export default DownloadSection