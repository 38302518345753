import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import TextSection from "./textSection"

import "./lightboxImageGallery.css"

const LightboxImageGallery = ( { data } ) => {

  return (

    <div className="lightboxImageGallery">

      <div className="description-container">
        {data.title_visible &&
          <Container>
            <AnimationWrapper>
              <TextSection data={data} />
            </AnimationWrapper>
          </Container>
        }
      </div>

      <div className={`image-gallery ${data.type}`}>
        <SimpleReactLightbox>
          <SRLWrapper>
            {data.photos.map((photo) => (
              <>
                {photo.status === "active" &&
                  <AnimationWrapper className="single-image-wrapper">
                    <a href={photo.image.publicURL}>
                      <GatsbyImage
                        image={photo.image.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    </a>
                  </AnimationWrapper>
                }
              </>
            ))}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>

    </div>
  )
}

export default LightboxImageGallery